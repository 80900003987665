<script setup lang="ts">
import type { NuxtError } from '#app'
import {getFullUrl} from "~/utils/url";
import delve from "dlv";

const props = defineProps({
  error: Object as () => NuxtError,
})

const showError = true

useSeoMeta({
  title: 'Erreur ' + props.error.statusCode +  ' | Planète Croisière',
  ogUrl: getFullUrl(),
  ogSiteName: 'Planète Croisière',
  robots: 'noindex, nofollow',
})
</script>

<template>
  <NuxtLayout>
    <div class="error">
      <span class="text--headline">Erreur</span>
      <h1>{{ error.statusCode }}</h1>
      <p v-if="showError">
        {{ error.message }}
      </p>
      <p v-if="!showError && error.statusCode == 500">
        Cette page est indisponible, veuillez nous excuser. L'équipe technique de Planète-Croisière a été informée.
      </p>

      <div
        v-if="showError"
        v-html="error.stack"
      />

      <h2 class="like-h5">
        Voici quelques liens utiles que nous vous proposons :
      </h2>

      <ul class="list-link">
        <li>
          <UiElementsButton
            color-style="outline"
            to="search"
          >
            Recherche de croisières
          </UiElementsButton>
        </li>
        <li>
          <UiElementsButton
            color-style="outline"
            to="destinations"
          >
            Toutes les destinations
          </UiElementsButton>
        </li>
        <li>
          <UiElementsButton
            color-style="outline"
            to="cruiselines"
          >
            Toutes les compagnies
          </UiElementsButton>
        </li>
      </ul>

      <ul class="list-link">
        <li>
          <UiElementsButton to="/">
            Retour à la page d'accueil
          </UiElementsButton>
        </li>
      </ul>
    </div>
  </NuxtLayout>
</template>

<style scoped lang="scss">
.error {
  margin-top: 6rem;
  text-align: center;
}

.text--headline {
  font-size: 1.5rem;
  font-weight: 200;
}

h1 {
  margin-top: 0;
  line-height: 3rem;
  font-size: 6rem;
}

.list-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 1em;
  padding: 0;
}
</style>
