import { default as _91slug_93M2iz2ymWiPMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/[slug].vue?macro=true";
import { default as aboutnfkbdpKTphMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/about.vue?macro=true";
import { default as contact0eviRSurz3Meta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/contact.vue?macro=true";
import { default as _91slug_93QwaQQNaqZkMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/cruiselines/[slug].vue?macro=true";
import { default as index7cz9cT367ZMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/cruiselines/index.vue?macro=true";
import { default as _91slug_93D1W4ZfAeSaMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/cruises/[slug].vue?macro=true";
import { default as _91slug_93IuUIC8tll7Meta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/departure-port/[slug].vue?macro=true";
import { default as index0UCatF22vOMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/departure-port/index.vue?macro=true";
import { default as _91slug_93BkjDuMP8bpMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/destinations/[slug].vue?macro=true";
import { default as indexAepJp8S8YXMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/destinations/index.vue?macro=true";
import { default as index8AvJTQk3wEMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/faq/index.vue?macro=true";
import { default as indexO4Ay2BVcYvMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/index.vue?macro=true";
import { default as indexMn3zmloeQhMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/search/index.vue?macro=true";
import { default as _91slug_93xcxfoNUcNsMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/ships/[slug].vue?macro=true";
import { default as indexvwufrkqkhNMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/ships/index.vue?macro=true";
import { default as sitemapxKWwYZNx21Meta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/sitemap.vue?macro=true";
import { default as style_45guideXO7ZMHLOlEMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/pages/style-guide.vue?macro=true";
import { default as component_45stubOeqF0PAIXyMeta } from "/var/www/planete-croisiere/prod/atrium/releases/114/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubOeqF0PAIXy } from "/var/www/planete-croisiere/prod/atrium/releases/114/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___fr",
    path: "/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/[slug].vue")
  },
  {
    name: "about___fr",
    path: "/infos-utiles",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/about.vue")
  },
  {
    name: "contact___fr",
    path: "/contact",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/contact.vue")
  },
  {
    name: "cruiselines-slug___fr",
    path: "/compagnie/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/cruiselines/[slug].vue")
  },
  {
    name: "cruiselines___fr",
    path: "/compagnie",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/cruiselines/index.vue")
  },
  {
    name: "cruises-slug___fr",
    path: "/croisiere/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/cruises/[slug].vue")
  },
  {
    name: "departure-port-slug___fr",
    path: "/ports-de-depart/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/departure-port/[slug].vue")
  },
  {
    name: "departure-port___fr",
    path: "/ports-de-depart",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/departure-port/index.vue")
  },
  {
    name: "destinations-slug___fr",
    path: "/destination/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/destinations/[slug].vue")
  },
  {
    name: "destinations___fr",
    path: "/destination",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/destinations/index.vue")
  },
  {
    name: "faq___fr",
    path: "/faq",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/faq/index.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/index.vue")
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/search/index.vue")
  },
  {
    name: "ships-slug___fr",
    path: "/navire/:slug()",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/ships/[slug].vue")
  },
  {
    name: "ships___fr",
    path: "/navire",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/ships/index.vue")
  },
  {
    name: "sitemap___fr",
    path: "/plan-du-site",
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/sitemap.vue")
  },
  {
    name: "style-guide___fr",
    path: "/style-guide",
    meta: style_45guideXO7ZMHLOlEMeta || {},
    component: () => import("/var/www/planete-croisiere/prod/atrium/releases/114/pages/style-guide.vue")
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/themes/croisieres-en-norvege/pourquoi-choisir-la-croisiere-en-scandinavie",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/themes/croisieres-de-luxe",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/themes/croisieres-en-norvege/comment-organiser-au-mieux-sa-croisiere-en-norvege",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/themes/croisieres-en-norvege/quels-sont-les-avantages-dune-croisiere-en-petit-bateau-dans-les-fjords-norvegiens",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/themes/reprise-croisiere-protocoles-sanitaires",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/costa-magica",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/costa-magica/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/croisiere-explora-i",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere-explora-i/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/compagnie/croisieres-maritimes-et-voyages",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisieres-maritimes-et-voyages/compagnie",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/compagnie/croisimer",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisimer/compagnie",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/compagnie/paul-gauguin-cruises",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/paul-gauguin-cruises/compagnie",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/costa-firenze",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/costa-firenze/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/costa-luminosa",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/costa-luminosa/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/infos-utiles/abonnement-newsletter",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/ocean-diamond",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/ocean-diamond/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/msc-europa",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/msc-europa/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/ms-lofoten",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/ms-lofoten/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/insigna",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/insigna/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/tour-du-spitzberg-au-royaume-de-l-ours-polaire-1",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/tour-du-spitzberg-au-royaume-de-l-ours-polaire-1/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/aventure-arctique-7",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/aventure-arctique-7/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/expedition-autour-du-spitzberg-1",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/expedition-autour-du-spitzberg-1/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/bergen-kirkenes-bergen-avec-accompagnateur-1",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/bergen-kirkenes-bergen-avec-accompagnateur-1/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/aventure-arctique",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/aventure-arctique/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/aventure-arctique-6",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/aventure-arctique-6/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/navire/celebrity-beyond-2",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/celebrity-beyond-2/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/costa-fortuna-martinique-guadeloupe-republique-dominicaine-iles-vierges-britanniques-saint-martin-4",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/costa-fortuna-martinique-guadeloupe-republique-dominicaine-iles-vierges-britanniques-saint-martin-4/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/costa-luminosa-italie-france-espagne-malte-grece-9",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/costa-luminosa-italie-france-espagne-malte-grece-9/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/croisiere/rois-empereurs",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/rois-empereurs/croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/destination/polaire-spitzberg",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/polaire-spitzberg/destination",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/infos-utiles/l-equipe-planete-croisiere",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/infos-utiles/bien-choisir-ma-cabine",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/infos-utiles/questions-frequentes",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/lp/offre-exclusive-credit-bord-msc",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/lp/offre-speciale-msc-lirica-venise-santorin-et-mykonos",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/demandes-informations",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/ponant/navire",
    component: component_45stubOeqF0PAIXy
  },
  {
    name: component_45stubOeqF0PAIXyMeta?.name,
    path: "/qui-sommes-nous",
    component: component_45stubOeqF0PAIXy
  }
]