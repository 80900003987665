import {ref} from 'vue';

export function useABTesting() {
  const variantsExperimentVersion = ref(['original', 'v2'])
  let experimentVersion = useCookie('experiment-version')
  onBeforeMount(() => {
    if ('undefined' === typeof experimentVersion.value) {
      experimentVersion.value = variantsExperimentVersion.value[
        Math.floor(Math.random() * variantsExperimentVersion.value.length)
      ]
    }
  })

  return {experimentVersion};
}
